import React from 'react'
import './about.css'
import WILLIAM from '../../assets/william.png'
import {FaAward} from 'react-icons/fa'
import {TiCodeOutline} from 'react-icons/ti'

const About = () => {
  return (
    <section id='about'>
      <h5>Learn More</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={WILLIAM} alt="picture of myself" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
          <article className="about__card">
              <FaAward className="about__icon"/>
              <h5>Navy Veteran</h5>
              <small>8 Years Of Active Duty</small>
            </article>

            <article className="about__card">
              <TiCodeOutline className="about__icon"/>
              <h5>LEARN Academy</h5>
              <small>4-Month Coding Bootcamp</small>
            </article>
          </div>

          <p>
             Hi, my name is William and I am a Web Developer based in Holly Springs, NC. I have a strong passion for creating new experiences, and sharing in those experiences with others.
            <br />
            In my previous line of work serving in the Navy, I developed an eye for detail and the ability to collaborate in a team environment effectively. As an avid learner, I look forward to gaining new insights and growing as a Junior Web Developer.
          </p>
        </div>
      </div>
      
    </section>
  )
}

export default About
