import React from 'react'
import './header.css'
import CTA from './CTA'
import WILLIAM from '../../assets/william-about.jpg'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>William Segree!</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA />
        <HeaderSocials />

        <div className="william">
          <img src={WILLIAM} alt="a picture of myself" className="img__radius"/>
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header