import React from 'react'
import './contact.css'
import {MdOutlineMail} from 'react-icons/md'
import {useRef} from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form =useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_53bv5pk', 'template_q2pwzqo', form.current, '9CeCi8wx0J_sJkBJN')
      
    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className="container contact__container">
        <div className="contact__options">
            <article>
            <MdOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>williamsegree@gmail.com</h5>
            <a href="mailto:williamsegree@gmail.com">Send a message</a>
          </article>
        </div>
        {/* End of contact option */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact