import React from 'react'
import './project.css'
import IMG1 from '../../assets/paw-spot.png'
import IMG2 from '../../assets/cat-tinder.png'
import IMG3 from '../../assets/emoji-tic-tac-toe.png'


const Project = () => {
  return (
    <section id="project">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container project__container">
        <article className="project__item">
          <div className="project__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>Paw Spot</h3>
          <div className="project-item-cta">
            <a href="https://github.com/Delta-Unleashed/paw-spot" target="_blank" className="btn">Github</a>
            <a href="https://the-paw-spot.herokuapp.com/" target="_blank" className="btn btn-primary">Live Demo</a>
          </div>
        </article>
        <article className="project__item">
          <div className="project__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>Cat-Tinder</h3>
          <div className="project-item-cta">
            <a href="https://github.com/Delta-Unleashed/paw-spot" target="_blank" className="btn">Github</a>
            {/* <a href="https://github.com/Delta-Unleashed/paw-spot" target="_blank" className="btn btn-primary">Live Demo</a> */}
          </div>
        </article>
        <article className="project__item">
          <div className="project__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>Emoji-Tic-Tac-Toe</h3>
          <div className="project__item-cta">
            <a href="https://github.com/Delta-Unleashed/paw-spot" target="_blank" className="btn">Github</a>
            {/* <a href="https://github.com/Delta-Unleashed/paw-spot" target="_blank" className="btn btn-primary">Live Demo</a> */}
          </div>
        </article>
      </div>
    </section>
  )
}

export default Project